<template>
    <div class="user-index">
        <div>
            <div class="head">
                <div class="info">
                    <div class="image" @click="toEditUserInfo()" >
                          <van-image
                          round
                          width=" 1.49333rem"
                          height=" 1.49333rem"
                          :src="userInfo.avatar"
                    />
                    </div>
                    <div class="user-info">
                        <div class="name">
                            <span>{{userInfo.nickname}}</span>
                        </div>
                    </div>
                </div>

                <div class="setting" @click="toSetting">
                    <van-icon name="setting-o" size="0.8rem" />
                </div>
            </div>
            <div class="head-placeholder bg-white"></div>
				<div>
					<van-cell-group class="user-group">
						<van-cell icon="records" title="全部订单" is-link :to="{path:'/user/attestation/orderlist'}"/>
						<van-row class="user-links">
							<van-col span="8" @click="toOrderList(0)">
								<van-icon name="pending-payment" size="0.7rem"/>
								待付款
							</van-col>
							<van-col span="8" @click="toOrderList(1)">
								<van-icon name="underway-o" size="0.7rem" />
								待验票
							</van-col>
							<van-col span="8" @click="toOrderList(2)">
								<van-icon name="passed" size="0.7rem" />
								已完成
							</van-col>
						</van-row>
					</van-cell-group>


					<van-cell-group class="user-group">
						<van-cell  title="" />
						<van-grid>
							<van-grid-item  text="我的收藏" :to="{path:'/user/attestation/mycollect'}">
								<template v-slot:icon>
									 <i class="iconfont icon-wuxiaoxing-kong"/>
								</template>
							</van-grid-item>
							<van-grid-item  text="我的评论" :to="{path:'/user/attestation/comment'}">
								<template v-slot:icon>
									 <i class="iconfont icon-pinglun"></i>
								</template>
							</van-grid-item>
							<van-grid-item  text="爆款订单" :to="{path:'/activity/marketing/hot/order'}">
								<template v-slot:icon>
									 <i class="iconfont icon-baokuan"></i>
								</template>
							</van-grid-item>
							<van-grid-item  text="拼团订单" :to="{path:'/activity/marketing/group/order'}">
								<template v-slot:icon>
									 <i class="iconfont icon-iconfonttuan"></i>
								</template>
							</van-grid-item>
						</van-grid>
					</van-cell-group>
				</div>

     <!-- 占位 -->
    <!-- 底部导航 -->
    <van-grid clickable :column-num="3" center icon-size="25px"  class="nav-bottom text-base">
	  <van-grid-item icon="wap-home-o" text="首页" :to="{path:'/home'}" />
      <van-grid-item icon="flag-o" text="同城" :to="{path:'/home/nearby'}" />
      <van-grid-item icon="manager-o" text="我的" class="text-theme2" :to="{path:'/user'}" />
    </van-grid>

        </div>
    </div>
</template>

<script>
    import {Row, Col, Icon, Cell, CellGroup,Tag ,Tab, Tabs,Grid, GridItem} from 'vant';
    import {
		mapState
	} from 'vuex';


    export default {
        components: {
            [Row.name]: Row,
            [Col.name]: Col,
            [Icon.name]: Icon,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tag .name]: Tag,
            [Tab .name]: Tab,
            [Tabs .name]: Tabs,
            [Grid .name]: Grid,
            [GridItem .name]: GridItem,
        },
        data(){
            return{
            }
        },
        computed:{
            userInfo(){
                return this.$store.state.userInfo;
            }
        },
        mounted:function(){},
        methods:{
            //选项卡
            toTabs(e){
                this.status = e;
            },
            //编辑个人资料
            toEditUserInfo(){
                this.$router.push({path: "/user/edit/userInfo",query:{id:this.userInfo.id}});
            },
            //设置账号信息
            toSetting(){
                this.$router.push({path:"/user/edit/info",query:{id:this.userInfo.id}});
            },
            //参与活动订单
            toOrderList(status){
                this.$router.push({path:"/user/attestation/orderlist",query:{status:status}});
            }
        }
    };
</script>

<style lang="less">
    .user {
        &-poster {
            width: 100%;
            height: 53vw;
            display: block;
        }

        &-group {
            margin-bottom: 15px;
            margin-top: 15px;
        }

        &-links {
            padding: 15px 0;
            font-size: 12px;
            text-align: center;
            background-color: #fff;

            .van-icon {
                display: block;
                font-size: 24px;
            }
        }
    }
   .user-index{
       .head-placeholder{
           height: 10px;
       }
    .head {
        padding: 15px 10px;
        position: relative;
        height: 150px;
        background-color: #3487e2;
        background-image: url("../../assets/images/userbackage.png");
        background-position: center;
        background-size: 100% auto;
        background-repeat: no-repeat;
        
        .info {
            position: absolute;
            bottom: 20px;
            display: flex;
            align-items: center;
            .image {
                width: 56px;
                height: 56px;
                background-color: #E5E5E5;
                border-radius: 50%;
                margin-right: 10px;
            }
            .name {
                font-size: 16px;
                color: white;
            }
        }
        .setting{
            position: absolute;
            color: white;
            right: 15px;
        }
        .id{
            position: absolute;
            right: 15px; 
            bottom: 10px;
        }
    }
    .userrow{
        margin-top: 20px;
        .usergrid{
            display: flex;
            justify-content: center;
            .grid-item{
                line-height: 18px;
                div{
                    font-size: 16px;
                    color: black;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                }
                label{
                    font-size: 14px;
                    color: #FF6F18;
                }
            }
        }
    }
    .user-group{
        margin-bottom: 5px;
           .iconfont{
               font-size:22px;
            //    color: rgba(0,0,0,.7);
            color: black;
			margin-right: 0;
           }
    }
    .activity{
        padding-bottom:  0.13333rem !important;
    }
   }
</style>
